import ContentBlock from "../components/ContentBlock";
import letspair from "../icons/experiences/letspair.png";
import sap from "../icons/experiences/sap.png";
import hypatia from "../icons/experiences/hypatia.png";
import piccolo from "../icons/experiences/piccolo.png";
import "../css/pages/Experiences.css";

const experiencesArray = [
  {
    company: "Let's Pair Education",
    icon: letspair,
    duration: "September 2024 - Present",
    position: "Full-Stack Developer",
    content: `Building Let's Pair's web application with Next.js`,
  },
  {
    company: "SAP",
    icon: sap,
    duration: "May 2022 - December 2022",
    position: "Software Developer Intern",
    content: `Worked on SAP Analytic Cloud (SAC) Data Blending, Dynamic Image Widget, and Batch Exporting`,
  },
  {
    company: "SAP",
    icon: sap,
    duration: "January 2021 - August 2021",
    position: "Analytics Developer Intern",
    content: `Worked on SAP Analytics Cloud (SAC) Data Blending`,
  },
  {
    company: "Hypatia System",
    icon: hypatia,
    duration: "September 2020 - December 2020",
    position: "Developer Intern",
    content: `Worked on feature development on Hypatia's Google Suite add-on software, 
        which is a Math E-learning tool used by over millions of users`,
  },
  {
    company: "Piccolo Software",
    icon: piccolo,
    duration: "September 2018 - April 2019",
    position: "Software Developer Intern",
    content: `Responsible for maintaining and implementing new features for Piccolo 
        Android app used by taxi companies`,
  },
];

const Experiences = () => {
  return (
    <div className="experiences-content">
      <h1>Experiences</h1>
      {experiencesArray.map((experience) => {
        return (
          <ContentBlock
            section={"experiences"}
            icon={experience.icon}
            company={experience.company}
            position={experience.position}
            duration={experience.duration}
            content={experience.content}
          />
        );
      })}
    </div>
  );
};

export default Experiences;
